@import "~@config/config.less";

.site-main-page {
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 30px;


    .rowLyr {
        width: 100%;
        height: 560px;
        display: flex;
        flex-direction: row;
        align-content: center;

        .item {
            width: 50%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-content: flex-start;
            justify-content: flex-start;
        }

        .title {
            width: 600px;
            text-align: left;
            font-size: 25px;
            font-weight: 600;
            margin-top: 100px;
            margin-left: 250px;
        }

        .desc {
            width: 600px;
            text-align: left;
            font-size: 16px;
            margin-top: 20px;
            margin-left: 250px;
        }

        .img {
            width: 800px;
            height: 557px;
        }
    }

}