@import "~@config/config.less";

.site-layout .header-layout {
    background-color: white;
    padding: 0;
    text-align: center;
}

.ant-layout-sider {
    background-color: white;
}

.site-layout .footer-layout {
    background-color: white;
    text-align: center;
}

.site-layout .title-font {
    font-size: 25px;
    color: black;
}